import axios from "axios";
import _ from "lodash";

export function getDrugInfoRequest(drugName) {
  const api_id = process.env.REACT_APP_API_ID;
  const api_key = process.env.REACT_APP_API_KEY;
  const url =
    process.env.REACT_APP_API_BASE_BIOGARAN_URL +
    "/api/product/full/search?api_app_id=" +
    api_id +
    "&&api_app_key=" +
    api_key +
    "&&query=" +
    drugName;

  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((response) => {
        if (response && response.status === 200) {
          resolve(response);
        } else {
          reject(response);
        }
      })
      .catch((err) => reject(err));
  });
}

export function sendFormPvRequest(formData) {
  let date = new Date();
  let dateString =
    ("0" + date.getDate()).slice(-2) +
    "/" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "/" +
    date.getFullYear();
  let dataFormToSend = {
    app_tpa_id: !_.isEmpty(formData.home.app_tpa_id)
      ? formData.home.app_tpa_id
      : "",
    pvi_type: !_.isEmpty(formData.home.pvi_type) ? formData.home.pvi_type : "",
    app_date: dateString,
    app_user: formData.pv ? formData.pv.app_user : "",
    pv_person_with_side_effect: {
      pvi_pv_prenom:
        !_.isEmpty(formData.pv.pv_person_with_side_effect) &&
        !_.isEmpty(formData.pv.pv_person_with_side_effect.pvi_pv_prenom)
          ? formData.pv.pv_person_with_side_effect.pvi_pv_prenom
          : "",
      pvi_pv_nom:
        !_.isEmpty(formData.pv.pv_person_with_side_effect) &&
        !_.isEmpty(formData.pv.pv_person_with_side_effect.pvi_pv_nom)
          ? formData.pv.pv_person_with_side_effect.pvi_pv_nom
          : "",
      pvi_pv_sexe:
        !_.isEmpty(formData.pv.pv_person_with_side_effect) &&
        !_.isEmpty(formData.pv.pv_person_with_side_effect.pvi_pv_sexe)
          ? formData.pv.pv_person_with_side_effect.pvi_pv_sexe
          : "",
      pvi_pv_date_naissance:
        !_.isEmpty(formData.pv.pv_person_with_side_effect) &&
        !_.isEmpty(formData.pv.pv_person_with_side_effect.pvi_pv_date_naissance)
          ? formData.pv.pv_person_with_side_effect.pvi_pv_date_naissance
          : "",
      pvi_pv_poids:
        !_.isEmpty(formData.pv.pv_person_with_side_effect) &&
        !_.isEmpty(formData.pv.pv_person_with_side_effect.pvi_pv_poids)
          ? formData.pv.pv_person_with_side_effect.pvi_pv_poids
          : "",
      pvi_pv_taille:
        !_.isEmpty(formData.pv.pv_person_with_side_effect) &&
        !_.isEmpty(formData.pv.pv_person_with_side_effect.pvi_pv_taille)
          ? formData.pv.pv_person_with_side_effect.pvi_pv_taille
          : "",
      pvi_pv_groupe_age:
        !_.isEmpty(formData.pv.pv_person_with_side_effect) &&
        !_.isEmpty(formData.pv.pv_person_with_side_effect.pvi_pv_groupe_age)
          ? formData.pv.pv_person_with_side_effect.pvi_pv_groupe_age
          : "",
    },
    pv_pharmacovigilance_situation: {
      pvi_pv_desciption_probleme:
        !_.isEmpty(formData.pv.pv_pharmacovigilance_situation) &&
        !_.isEmpty(
          formData.pv.pv_pharmacovigilance_situation.pvi_pv_desciption_probleme
        )
          ? formData.pv.pv_pharmacovigilance_situation
              .pvi_pv_desciption_probleme
          : "",
      pvi_pv_hospitalisation:
        !_.isEmpty(formData.pv.pv_pharmacovigilance_situation) &&
        !_.isEmpty(
          formData.pv.pv_pharmacovigilance_situation.pvi_pv_hospitalisation
        )
          ? formData.pv.pv_pharmacovigilance_situation.pvi_pv_hospitalisation
          : "",
      pvi_pv_examen:
        !_.isEmpty(formData.pv.pv_pharmacovigilance_situation) &&
        !_.isEmpty(formData.pv.pv_pharmacovigilance_situation.pvi_pv_examen)
          ? formData.pv.pv_pharmacovigilance_situation.pvi_pv_examen
          : "",
      pvi_pv_date_start:
        !_.isEmpty(formData.pv.pv_pharmacovigilance_situation) &&
        !_.isEmpty(formData.pv.pv_pharmacovigilance_situation.pvi_pv_date_start)
          ? formData.pv.pv_pharmacovigilance_situation.pvi_pv_date_start
          : "",
      pvi_pv_date_end:
        !_.isEmpty(formData.pv.pv_pharmacovigilance_situation) &&
        !_.isEmpty(formData.pv.pv_pharmacovigilance_situation.pvi_pv_date_end)
          ? formData.pv.pv_pharmacovigilance_situation.pvi_pv_date_end
          : "",
      pvi_pv_exam_response:
        !_.isEmpty(formData.pv.pv_pharmacovigilance_situation) &&
        !_.isEmpty(
          formData.pv.pv_pharmacovigilance_situation.pvi_pv_exam_response
        )
          ? formData.pv.pv_pharmacovigilance_situation.pvi_pv_exam_response
          : "",
    },
    pv_side_effect_event: _.isArray(
      formData.pv.pv_side_effect_event.personSideEffectEvent
    )
      ? formData.pv.pv_side_effect_event.personSideEffectEvent
      : [],
    pv_side_effect_following_substitution: {
      pvi_pv_following_substitution:
        !_.isEmpty(formData.pv.pv_side_effect_following_substitution) &&
        !_.isEmpty(
          formData.pv.pv_side_effect_following_substitution
            .pvi_pv_following_substitution
        )
          ? formData.pv.pv_side_effect_following_substitution
              .pvi_pv_following_substitution
          : "",
      pvi_pv_substitution_type:
        !_.isEmpty(formData.pv.pv_side_effect_following_substitution) &&
        !_.isEmpty(
          formData.pv.pv_side_effect_following_substitution
            .pvi_pv_substitution_type
        )
          ? formData.pv.pv_side_effect_following_substitution
              .pvi_pv_substitution_type
          : "",
      pvi_pv_which:
        !_.isEmpty(formData.pv.pv_side_effect_following_substitution) &&
        !_.isEmpty(
          formData.pv.pv_side_effect_following_substitution.pvi_pv_which
        )
          ? formData.pv.pv_side_effect_following_substitution.pvi_pv_which
          : "",
      pvi_pv_start_date:
        !_.isEmpty(formData.pv.pv_side_effect_following_substitution) &&
        !_.isEmpty(
          formData.pv.pv_side_effect_following_substitution.pvi_pv_start_date
        )
          ? formData.pv.pv_side_effect_following_substitution.pvi_pv_start_date
          : "",
      pvi_pv_end_date:
        !_.isEmpty(formData.pv.pv_side_effect_following_substitution) &&
        !_.isEmpty(
          formData.pv.pv_side_effect_following_substitution.pvi_pv_end_date
        )
          ? formData.pv.pv_side_effect_following_substitution.pvi_pv_end_date
          : "",
      pvi_pv_side_effect_patient:
        !_.isEmpty(formData.pv.pv_side_effect_following_substitution) &&
        !_.isEmpty(
          formData.pv.pv_side_effect_following_substitution
            .pvi_pv_side_effect_patient
        )
          ? formData.pv.pv_side_effect_following_substitution
              .pvi_pv_side_effect_patient
          : "",
      pvi_pv_drug_reintroduced:
        !_.isEmpty(formData.pv.pv_side_effect_following_substitution) &&
        !_.isEmpty(
          formData.pv.pv_side_effect_following_substitution
            .pvi_pv_drug_reintroduced
        )
          ? formData.pv.pv_side_effect_following_substitution
              .pvi_pv_drug_reintroduced
          : "",
      pvi_pv_drug_reintroduced_date:
        !_.isEmpty(formData.pv.pv_side_effect_following_substitution) &&
        !_.isEmpty(
          formData.pv.pv_side_effect_following_substitution
            .pvi_pv_drug_reintroduced_date
        )
          ? formData.pv.pv_side_effect_following_substitution
              .pvi_pv_drug_reintroduced_date
          : "",
      pvi_pv_recidivism:
        !_.isEmpty(formData.pv.pv_side_effect_following_substitution) &&
        !_.isEmpty(
          formData.pv.pv_side_effect_following_substitution.pvi_pv_recidivism
        )
          ? formData.pv.pv_side_effect_following_substitution.pvi_pv_recidivism
          : "",
    },
    pv_gravity: {
      gravity: !_.isEmpty(formData.pv.pv_gravity)
        ? formData.pv.pv_gravity.gravity
        : "",
      pvi_pv_result: [
        !_.isEmpty(formData.pv.pv_gravity) &&
        _.isArray(formData.pv.pv_gravity.pvi_pv_result)
          ? formData.pv.pv_gravity.pvi_pv_result
          : "",
      ],
      pvi_pv_hospitalization_start_date:
        !_.isEmpty(formData.pv.pv_gravity) &&
        !_.isEmpty(formData.pv.pv_gravity.pvi_pv_hospitalization_start_date)
          ? formData.pv.pv_gravity.pvi_pv_hospitalization_start_date
          : "",
      pvi_pv_hospitalization_end_date:
        !_.isEmpty(formData.pv.pv_gravity) &&
        !_.isEmpty(formData.pv.pv_gravity.pvi_pv_hospitalization_end_date)
          ? formData.pv.pv_gravity.pvi_pv_hospitalization_end_date
          : "",
      pvi_pv_death_date:
        !_.isEmpty(formData.pv.pv_gravity) &&
        !_.isEmpty(formData.pv.pv_gravity.pvi_pv_death_date)
          ? formData.pv.pv_gravity.pvi_pv_death_date
          : "",
      pvi_pv_cause_death:
        !_.isEmpty(formData.pv.pv_gravity) &&
        !_.isEmpty(formData.pv.pv_gravity.pvi_pv_cause_death)
          ? formData.pv.pv_gravity.pvi_pv_cause_death
          : "",
    },
    pv_professionnel_details: {
      pvi_pv_rappel:
        !_.isEmpty(formData.pv.pv_professionnel_details) &&
        !_.isEmpty(formData.pv.pv_professionnel_details.pvi_pv_rappel)
          ? formData.pv.pv_professionnel_details.pvi_pv_rappel
          : "",
      pvi_pv_prenom_pro:
        !_.isEmpty(formData.pv.pv_professionnel_details) &&
        !_.isEmpty(formData.pv.pv_professionnel_details.pvi_pv_prenom_pro)
          ? formData.pv.pv_professionnel_details.pvi_pv_prenom_pro
          : "",
      pvi_pv_nom_pro:
        !_.isEmpty(formData.pv.pv_professionnel_details) &&
        !_.isEmpty(formData.pv.pv_professionnel_details.pvi_pv_nom_pro)
          ? formData.pv.pv_professionnel_details.pvi_pv_nom_pro
          : "",
      pvi_pv_address_pro:
        !_.isEmpty(formData.pv.pv_professionnel_details) &&
        !_.isEmpty(formData.pv.pv_professionnel_details.pvi_pv_address_pro)
          ? formData.pv.pv_professionnel_details.pvi_pv_address_pro
          : "",
      pvi_pv_cp_pro:
        !_.isEmpty(formData.pv.pv_professionnel_details) &&
        !_.isEmpty(formData.pv.pv_professionnel_details.pvi_pv_cp_pro)
          ? formData.pv.pv_professionnel_details.pvi_pv_cp_pro
          : "",
      pvi_pv_city_pro:
        !_.isEmpty(formData.pv.pv_professionnel_details) &&
        !_.isEmpty(formData.pv.pv_professionnel_details.pvi_pv_city_pro)
          ? formData.pv.pv_professionnel_details.pvi_pv_city_pro
          : "",
      pvi_pv_phone_pro:
        !_.isEmpty(formData.pv.pv_professionnel_details) &&
        !_.isEmpty(formData.pv.pv_professionnel_details.pvi_pv_phone_pro)
          ? formData.pv.pv_professionnel_details.pvi_pv_phone_pro
          : "",
      pvi_pv_mail_pro:
        !_.isEmpty(formData.pv.pv_professionnel_details) &&
        !_.isEmpty(formData.pv.pv_professionnel_details.pvi_pv_mail_pro)
          ? formData.pv.pv_professionnel_details.pvi_pv_mail_pro
          : "",
    },
    pv_side_effect_description: {
      pv_side_effects:
        !_.isEmpty(formData.pv.pv_side_effect_description) &&
        _.isArray(formData.pv.pv_side_effect_description.pv_side_effects)
          ? formData.pv.pv_side_effect_description.pv_side_effects
          : [],
    },
    pv_medical_background: {
      medicalBackgrounds:
        !_.isEmpty(formData.pv.pv_medical_background) &&
        _.isArray(formData.pv.pv_medical_background.medicalBackgrounds)
          ? formData.pv.pv_medical_background.medicalBackgrounds
          : [],
    },
    pv_ongoing_treatment: {
      ongoingTreatments:
        !_.isEmpty(formData.pv.pv_ongoing_treatment) &&
        _.isArray(formData.pv.pv_ongoing_treatment.ongoingTreatments)
          ? formData.pv.pv_ongoing_treatment.ongoingTreatments
          : [],
    },
    pv_drug_concerned: {
      drugConcerned:
        !_.isEmpty(formData.pv.pv_drug_concerned) &&
        _.isArray(formData.pv.pv_drug_concerned.drugConcerned)
          ? formData.pv.pv_drug_concerned.drugConcerned
          : [],
    },
    pv_drug_history: {
      drugHistory:
        !_.isEmpty(formData.pv.pv_drug_history) &&
        _.isArray(formData.pv.pv_drug_history.drugHistory) &&
        formData.pv.pv_drug_history.drugHistory,
    },
    app_identity: {
      app_specialite:
        !_.isEmpty(formData.pv.app_identity) &&
        !_.isEmpty(formData.pv.app_identity.app_specialite)
          ? formData.pv.app_identity.app_specialite
          : "",
      app_etablissement:
        !_.isEmpty(formData.pv.app_identity) &&
        !_.isEmpty(formData.pv.app_identity.app_etablissement)
          ? formData.pv.app_identity.app_etablissement
          : "",
      app_nom:
        !_.isEmpty(formData.pv.app_identity) &&
        !_.isEmpty(formData.pv.app_identity.app_nom)
          ? formData.pv.app_identity.app_nom
          : "",
      app_prenom:
        !_.isEmpty(formData.pv.app_identity) &&
        !_.isEmpty(formData.pv.app_identity.app_prenom)
          ? formData.pv.app_identity.app_prenom
          : "",
      app_email:
        !_.isEmpty(formData.pv.app_identity) &&
        !_.isEmpty(formData.pv.app_identity.app_email)
          ? formData.pv.app_identity.app_email
          : "",
      app_adresse:
        !_.isEmpty(formData.pv.app_identity) &&
        !_.isEmpty(formData.pv.app_identity.app_adresse)
          ? formData.pv.app_identity.app_adresse
          : "",
      app_code_postal:
        !_.isEmpty(formData.pv.app_identity) &&
        !_.isEmpty(formData.pv.app_identity.app_code_postal)
          ? formData.pv.app_identity.app_code_postal
          : "",
      app_ville:
        !_.isEmpty(formData.pv.app_identity) &&
        !_.isEmpty(formData.pv.app_identity.app_ville)
          ? formData.pv.app_identity.app_ville
          : "",
      app_pays: formData?.pv?.app_identity?.app_pays,
      app_telephone1:
        !_.isEmpty(formData.pv.app_identity) &&
        !_.isEmpty(formData.pv.app_identity.app_telephone1)
          ? formData.pv.app_identity.app_telephone1
          : "",
    },
  };

  return new Promise((resolve, reject) => {
    axios
      .post(process.env.REACT_APP_API_BASE_URL + "/save-pv", dataFormToSend)
      .then((response) => {
        if (response && response.status === 200) {
          resolve(response);
        } else {
          reject(response);
        }
      })
      .catch((err) => reject(err));
  });
}
/**
 * send Form Im Request
 * @param {*} formData
 */

export function sendFormImRequest(formData) {
  let d = new Date();
  let datestring =
    ("0" + d.getDate()).slice(-2) +
    "/" +
    ("0" + (d.getMonth() + 1)).slice(-2) +
    "/" +
    d.getFullYear();
  let dataFormToSend = {
    app_tpa_id: formData?.home?.app_tpa_id,
    pvi_type: formData?.home?.pvi_type,
    app_date: datestring,
    app_user: formData?.im?.app_user,
    im_app_identity: {
      app_specialite: formData?.im?.im_app_identity?.app_specialite,
      app_etablissement: formData?.im?.im_app_identity?.app_etablissement,
      app_nom: formData?.im?.im_app_identity?.app_nom,
      app_prenom: formData?.im?.im_app_identity?.app_prenom,
      app_email: formData?.im?.im_app_identity?.app_email,
      app_adresse: formData?.im?.im_app_identity?.app_adresse,
      app_code_postal: formData?.im?.im_app_identity?.app_code_postal,
      app_ville: formData?.im?.im_app_identity?.app_ville,
      app_pays: formData?.im?.im_app_identity?.app_pays,
      app_telephone1: formData?.im?.im_app_identity?.app_telephone1,
    },
    im_info_request: formData?.im?.im_info_request,
    im_drug_concern: formData?.im?.im_drug_concern,
  };
  return new Promise((resolve, reject) => {
    axios
      .post(process.env.REACT_APP_API_BASE_URL + "/save-im", dataFormToSend)
      .then((response) => {
        if (response && response.status === 200) {
          resolve(response);
        } else {
          reject(response);
        }
      })
      .catch((err) => reject(err));
  });
}

/**
 * send Form Aq Request
 * @param {*} formData
 */
export function sendFormAqRequest(formData) {
  let d = new Date();
  let datestring =
    ("0" + d.getDate()).slice(-2) +
    "/" +
    ("0" + (d.getMonth() + 1)).slice(-2) +
    "/" +
    d.getFullYear();

  let dataFormToSend = {
    app_tpa_id: formData.home && formData.home.app_tpa_id,
    pvi_type: formData.home && formData.home.pvi_type,
    app_date: datestring,
    app_user: formData.aq && formData.aq.app_user,
    app_identity: {
      app_specialite:
        formData.aq &&
        formData.aq.app_identity &&
        formData.aq.app_identity.app_specialite,
      app_etablissement:
        formData.aq &&
        formData.aq.app_identity &&
        formData.aq.app_identity.app_etablissement,
      app_nom:
        formData.aq &&
        formData.aq.app_identity &&
        formData.aq.app_identity.app_nom,
      app_prenom:
        formData.aq &&
        formData.aq.app_identity &&
        formData.aq.app_identity.app_prenom,
      app_email:
        formData.aq &&
        formData.aq.app_identity &&
        formData.aq.app_identity.app_email,
      app_adresse:
        formData.aq &&
        formData.aq.app_identity &&
        formData.aq.app_identity.app_adresse,
      app_code_postal:
        formData.aq &&
        formData.aq.app_identity &&
        formData.aq.app_identity.app_code_postal,
      app_ville:
        formData.aq &&
        formData.aq.app_identity &&
        formData.aq.app_identity.app_ville,
      app_pays: formData?.aq?.app_identity?.app_pays,
      app_telephone1:
        formData.aq &&
        formData.aq.app_identity &&
        formData.aq.app_identity.app_telephone1,
    },
    pvi_denomination: formData.aq && formData.aq.pvi_denomination,
    pvi_rqp_reclamation: [
      !_.isEmpty(formData.aq.pvi_rqp_reclamation) &&
        formData.aq.pvi_rqp_reclamation,
      !_.isEmpty(formData.aq.pvi_rqp_reclamation2) &&
        formData.aq.pvi_rqp_reclamation2,
      formData.aq && formData.aq.pvi_rqp_reclamation5,
    ],
    pvi_rqp_decouvert: formData.aq && formData.aq.pvi_rqp_decouvert,
    pvi_rqp_quand: formData.aq && formData.aq.pvi_rqp_quand,
  };

  let appFiles =
    !_.isEmpty(formData.aq.pvi_denomination) &&
    _.isArray(formData.aq.pvi_denomination.app_files) &&
    formData.aq.pvi_denomination.app_files;

  //we need to delete pvi_denomination.app_files
  delete dataFormToSend?.pvi_denomination?.app_files;
  delete dataFormToSend?.pvi_denomination?.drugPreviewPictures;

  return new Promise((resolve, reject) => {
    axios
      .post(process.env.REACT_APP_API_BASE_URL + "/save-aq", dataFormToSend)
      .then(async(response) => {
        if (response && response.status === 200) {
          if (!_.isEmpty(appFiles)) {
            for (const file of appFiles) {
              await fileUploadRequest(response.data.filePath, file);
            }
            resolve(response);
          } else {
            resolve(response);
          }
        } else {
          reject(response);
        }
      })
      .catch((err) => reject(err));
  });
}

/**
 * send file Request
 * @param {*} filePath
 * @param {*} file
 */
async function fileUploadRequest(filePath, file) {
  let fd = new FormData();
  fd.append("filePath", filePath);
  fd.append("filename", file.name);
  fd.append("file", file);

  return await axios
    .post(process.env.REACT_APP_API_BASE_URL + "/file-uploader", fd)
    .then((response) => {
      if (response) {
        console.log("file upload success");
      }
    });
}
