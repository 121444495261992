import {
  AQ_CLICK_NEXT_BTN,
  AQ_CLICK_PREV_BTN,
  AQ_SET_APP_IDENTITY,
  AQ_SET_PVI_DENOMINATION,
  AQ_SET_PVI_RQP_RECLAMATION,
  AQ_SET_PVI_RQP_RECLAMATION2,
  AQ_SET_PVI_RQP_RECLAMATION5,
  AQ_SET_PVI_RQP_DECOUVERT,
  AQ_SET_PVI_RQP_QUAND,
  SEND_FORM_AQ_PENDING,
  SEND_FORM_AQ_SUCCESS,
  SEND_FORM_AQ_FAILED,
} from "./actionTypes";
import { sendFormAqRequest } from "./../../utils/service";

/**
 * set page index value
 * @param {*} value
 */
export function clickNextButton(pageIndex) {
  return {
    type: AQ_CLICK_NEXT_BTN,
    payload: { pageIndex },
  };
}

/**
 * set page index value
 * @param {*} pageIndex
 */
export function clickPrevButton(pageIndex) {
  return {
    type: AQ_CLICK_PREV_BTN,
    payload: { pageIndex },
  };
}

/**
 * set App Identity
 * @param {*} value
 */
export function setAppIdentity(value) {
  return {
    type: AQ_SET_APP_IDENTITY,
    payload: { value },
  };
}

/**
 * setPviDenomination
 * @param {*} value
 */
export function setPviDenomination(value) {
  return {
    type: AQ_SET_PVI_DENOMINATION,
    payload: { value },
  };
}

/**
 * setPviRqpReclamation
 * @param {*} value
 */
export function setPviRqpReclamation(value) {
  return {
    type: AQ_SET_PVI_RQP_RECLAMATION,
    payload: { value },
  };
}
/**
 * setPviRqpReclamation2
 * @param {*} value
 */
export function setPviRqpReclamation2(value) {
  return {
    type: AQ_SET_PVI_RQP_RECLAMATION2,
    payload: { value },
  };
}
/**
 * setPviRqpReclamation5
 * @param {*} value
 */
export function setPviRqpReclamation5(value) {
  return {
    type: AQ_SET_PVI_RQP_RECLAMATION5,
    payload: { value },
  };
}

/**
 * setPviRqpDecouvert
 * @param {*} value
 */
export function setPviRqpDecouvert(value) {
  return {
    type: AQ_SET_PVI_RQP_DECOUVERT,
    payload: { value },
  };
}

/**
 * setPviRqpQuand
 * @param {*} value
 */
export function setPviRqpQuand(value) {
  return {
    type: AQ_SET_PVI_RQP_QUAND,
    payload: { value },
  };
}

/**
 * send Form AQ Action pending
 * @param {*} value
 */
export function sendFormAQPending(value) {
  return {
    type: SEND_FORM_AQ_PENDING,
    payload: { value },
  };
}

/**
 * send Form AQ Action
 * @param {*} value
 */
export function sendFormAQAction(value) {
  return {
    type: SEND_FORM_AQ_SUCCESS,
    payload: { value },
  };
}

/**
 * send Form AQ Failed
 * @param {*} value
 */
export function sendFormAQFailed(value) {
  return {
    type: SEND_FORM_AQ_FAILED,
    payload: { value },
  };
}

/**
 * action send Form AQ
 * @param {*} formData
 * @param {*} pageIndex
 */
export const sendFormAQ = (formData, pageIndex) => (dispatch) => {
  dispatch(sendFormAQPending(true));
  sendFormAqRequest(formData)
    .then(async (res) => {
      await dispatch(sendFormAQAction(pageIndex));
      dispatch(sendFormAQPending(false));
    })
    .catch((err) => {
      dispatch(sendFormAQFailed(err));
    });
};
