/*************************HOME************************************/
export const SET_APP_TPA_ID = "SET_APP_TPA_ID";
export const SET_PVI_TYPE = "SET_PVI_TYPE";
export const HOME_CLICK_NEXT_BTN = "HOME_CLICK_NEXT_BTN";
export const HOME_CLICK_PREV_BTN = "HOME_CLICK_PREV_BTN";
export const SET_APP_PRO_OR_NOPRO = "SET_APP_PRO_OR_NOPRO";
/*************************AQ************************************/
export const AQ_CLICK_NEXT_BTN = "AQ_CLICK_NEXT_BTN";
export const AQ_CLICK_PREV_BTN = "AQ_CLICK_PREV_BTN";
export const AQ_SET_APP_IDENTITY = "AQ_SET_APP_IDENTITY";
export const AQ_SET_PVI_DENOMINATION = "AQ_SET_PVI_DENOMINATION";
export const AQ_SET_PVI_RQP_RECLAMATION = "AQ_SET_PVI_RQP_RECLAMATION";
export const AQ_SET_PVI_RQP_RECLAMATION2 = "AQ_SET_PVI_RQP_RECLAMATION2";
export const AQ_SET_PVI_RQP_RECLAMATION5 = "AQ_SET_PVI_RQP_RECLAMATION5";
export const AQ_SET_PVI_RQP_DECOUVERT = "AQ_SET_PVI_RQP_DECOUVERT";
export const AQ_SET_PVI_RQP_QUAND = "AQ_SET_PVI_RQP_QUAND";
export const SEND_FORM_AQ_PENDING = "SEND_FORM_AQ_PENDING";
export const SEND_FORM_AQ_SUCCESS = "SEND_FORM_AQ_SUCCESS";
export const SEND_FORM_AQ_FAILED = "SEND_FORM_AQ_FAILED";

/************************PV**********************************/

export const PV_CLICK_NEXT_BTN = "PV_CLICK_NEXT_BTN";
export const PV_CLICK_PREV_BTN = "PV_CLICK_PREV_BTN";
export const PV_SET_PERSON_WITH_SIDE_EFFECT = "PV_SET_PERSON_WITH_SIDE_EFFECT";
export const PV_SET_PHARMACOVIGILANCE_SITUATION =
  "PV_SET_PHARMACOVIGILANCE_SITUATION";
export const PV_SET_SIDE_EFFECTS_DESCRIPTION =
  "PV_SET_SIDE_EFFECTS_DESCRIPTION";
export const PV_SET_DRUG_CONCERNED = "PV_SET_DRUG_CONCERNED";
export const PV_SET_SIDE_EFFECT_EVENT = "PV_SET_SIDE_EFFECT_EVENT";
export const PV_SET_APP_PRO_OR_NOPRO = "PV_SET_APP_PRO_OR_NOPRO";
export const PV_SET_PROFESSIONNEL_DETAILS = "PV_SET_PROFESSIONNEL_DETAILS";
export const PV_SET_MEDICAL_BACKGROUND = "PV_SET_MEDICAL_BACKGROUND";
export const PV_SET_GRAVITY = "PV_SET_GRAVITY";
export const PV_SET_ONGOING_TREATMENT = "PV_SET_ONGOING_TREATMENT";
export const PV_SET_EFFECT_FOLLOWING_SUBSTITUTION =
  "PV_SET_EFFECT_FOLLOWING_SUBSTITUTION";
export const PV_SET_DRUG_HISTORY = "PV_SET_DRUG_HISTORY";
export const PV_SET_APP_IDENTITY = "PV_SET_APP_IDENTITY";
export const SEND_FORM_PV_SUCCESS = "SEND_FORM_PV_SUCCESS";
export const SEND_FORM_PV_FAILED = "SEND_FORM_PV_FAILED";

/**************************************** IM **********************************/
export const IM_CLICK_PREV_BTN = "IM_CLICK_PREV_BTN";
export const IM_CLICK_NEXT_BTN = "IM_CLICK_NEXT_BTN";
export const IM_SET_DRUG_CONCERN = "IM_SET_DRUG_CONCERN";
export const IM_SET_INFO_REQUEST = "IM_SET_INFO_REQUEST";
export const IM_SET_APP_IDENTITY = "IM_SET_APP_IDENTITY";
export const SEND_FORM_IM_SUCCESS = "SEND_FORM_IM_SUCCESS";
export const SEND_FORM_IM_FAILED = "SEND_FORM_IM_FAILED";
